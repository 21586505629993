//ASSETS
import {ReactComponent as WhatsAppIcon} from '../../assets/images/whatsapp-line.svg'

//DEFAULT PROP VALUES
const defaultProps = {
    size: 24
}

export default function AiWhatsappIcon({size = defaultProps.size}){
    return(
        <a href={'https://api.whatsapp.com/send?phone=27871330033'} target={'_blank'} className={'ai-whatsapp-icon'} style={{minWidth: size, minHeight: size}}>
            <WhatsAppIcon width={size} height={size}/>
        </a>
    )
}